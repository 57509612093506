export const FirebaseConfig = {
	"projectId": "roride-4d51f",
	"appId": "1:283476513148:web:49937d8a5bb33a09cfd4f9",
	"databaseURL": "https://roride-4d51f-default-rtdb.firebaseio.com",
	"storageBucket": "roride-4d51f.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyArF3Wb6D9kKYAJqCZuGLiePrhv8dtIrA0",
	"authDomain": "roride-4d51f.firebaseapp.com",
	"messagingSenderId": "283476513148",
	"measurementId": "G-6TBL5RRNK3"
};